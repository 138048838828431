body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
  background-position: center; /* Center the background image */

}

/* Media query for screens smaller than 600px (typical mobile devices) */
@media only screen and (max-width: 600px) {
  body {
    background-position: center top 50px; /* Adjust background position 50px down from top */
  }
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.form-container {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-container input[type="text"] {
  width: calc(100% - 85px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.form-container button {
  padding: 10px 20px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-container button:hover {
  background-color: #cc1100;
}

.card {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card .longUrl {
  font-size: 18px;
  margin-bottom: 10px;
}

.card .shortUrl {
  font-size: 16px;
  color: #cc1100;
}

.card .shortUrl a {
  text-decoration: none;
  color: #cc1100;
}

.card .shortUrl a:hover {
  text-decoration: underline;
}
