body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f0f0f0;
}

.container {
  max-width: 300px; /* Limit width for larger screens */
  margin: 0 auto; /* Center the container */
  padding: 20px;
  box-sizing: border-box;
}

.header {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.form-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Add some space at the bottom */
}

.form-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-container input[type="text"] {
  width: calc(100% - 40px); /* Adjust input width */
  margin-bottom: 10px; /* Add spacing between input and button */
}

.form-container button {
  width: calc(100% - 40px); /* Adjust button width */
}

.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-bottom: 15px;
}

.card .info {
  display: flex;
  flex-direction: column;
}

.card .info .longUrl {
  font-size: 16px;
  font-weight: bold;
}

.card .info .shortUrl {
  font-size: 14px;
  color: #888;
}

#changeBtn {
  display: block;
  margin: 0 auto;
  margin-bottom: 20px; /* Add some space at the bottom */
}

